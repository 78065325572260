import { useState } from "react";

export namespace useUid {
    /**
     * {@link useUid} 関数のオプションの型
     */
    export interface Options {
        /** 接頭辞 */
        readonly prefix?: string;
    }
}

/**
 * コンポーネントに一意の文字列を生成します。
 *
 * @param options オプション
 * @returns 一意の文字列
 */
export function useUid(options: useUid.Options = {}): string {
    const { prefix = "" } = options;
    const [uid] = useState(() => crypto.randomUUID().replaceAll("-", ""));

    return `${prefix}${uid}`;
}
